import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'


export default function Gallery({ typeRef, customRef }) {




  useEffect(() => {
    const players = document.getElementsByClassName("vdo");
    let hasPlayed = [];


    for (let i = 0; i < players.length; i++) {
      hasPlayed.push(false);
      players[i].addEventListener('onplay', handleFirstPlay(i));
    }


    function handleFirstPlay(i) {
      if (hasPlayed[i] === false) {
        players[i].play();
        hasPlayed[i] = true;
      }
    };


    // if (players != null)
    //   players.addEventListener('onplay', handleFirstPlay());
  });



  // const Gallery = (props, ref) => {
  // console.log("0:" + customRef)

  const data = useStaticQuery(graphql`
        query CloudinaryImages {
            allCloudinaryMedia {
              edges {
                node {
                  type
                  secure_url
                  asset_id
                  url
                  version
                  created_at
                  resource_type
                  public_id
                  id
                  height
                  format
                  bytes
                  width
                  context {
                    custom {
                      alt
                      caption
                      path
                      video
                      link
                    }
                  }
                }
              }
              totalCount
            }
          }
          `);

  const clImages = data.allCloudinaryMedia.edges;
  // const clImagesTotalCount = data.allCloudinaryMedia.totalCount;
  let str = customRef.split("/");
  // console.log("clImagesTotalCount : " + clImagesTotalCount);
  // console.log("input : " + str[0] + "/" + str[1]);
  // const linkRef = React.createRef();F

  // console.log("typeRef : "+typeRef);



  return (
    clImages.map((image, index) => (
      // <li>{index.toString()}</li>
      ((image.node.public_id.split("/")[0] + "/" + image.node.public_id.split("/")[1]) == str[0] + "/" + str[1]) &&
      <li key={index.toString()}>
        <div>
          {
            (typeRef != "detail") ?
              <a href={(typeRef != "detail" && image.node.context != null) ? "/" + image.node.context.custom.path : "#"} rel={(image.node.context != null) && "/" + image.node.context.custom.path} title={(image.node.context != null) && image.node.context.custom.caption} className="dlink">
                <img loading="lazy" decoding="async" src={image.node.secure_url} alt={(image.node.context != null) && image.node.context.custom.alt} />
              </a>
              :
              (image.node.context != null) &&
                (image.node.context.custom.video != null) ?
                <video className="vdo" controls muted autoplay loop playsInline>
                    <source src={(image.node.context != null) && (image.node.context.custom.video).split("upload")[0] + "upload/q_auto" + (image.node.context.custom.video).split("upload")[1]} type="video/mp4" />
                </video>
                // <iframe src="https://www.youtube.com/embed/EZ57YtuJX8E?&autoplay=1&controls=0&loop=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                :
                <img loading="lazy" decoding="async" src={image.node.secure_url} alt={(image.node.context != null) && image.node.context.custom.alt} />
          }
        </div>
        <a href={(typeRef != "detail" && image.node.context != null) ? "/" + image.node.context.custom.path : "#"} rel={(image.node.context != null) && "/" + image.node.context.custom.path} title={(image.node.context != null) && image.node.context.custom.caption} className="dlink clickGroup">
          <span className="clickarrow">➜</span>
          <div className="clicktitle">{(image.node.context != null) ? (image.node.context.custom.caption == null) ? "" : image.node.context.custom.caption : ""}</div>
          {/* <div className="clicktitle">{(image.node.context != null) ? (image.node.context.custom.caption == null) ? "video title" : image.node.context.custom.caption : "image title"}</div> */}
          <div className="clicklink">{(image.node.context != null) ? (image.node.context.custom.link == null) ? "" : <a href={image.node.context.custom.link} target="_blank">{image.node.context.custom.link}</a> : ""}</div>
          <div className="clicknum">{ }</div>
          <span className="clickline"></span>
        </a>

        {/* <img src={image.node.secure_url} /> */}
        {/* {console.log("type : " + image.node.type)}
        {console.log("secure_url : " + image.node.secure_url)}
        {console.log("asset_id : " + image.node.asset_id)}
        {console.log("url : " + image.node.url)}
        {console.log("version : " + image.node.version)}
        {console.log("created_at : " + image.node.created_at)}
        {console.log("resource_type : " + image.node.resource_type)}
        {console.log("public_id : " + image.node.public_id)}
        {console.log("id : " + image.node.id)}
        {console.log("width : " + image.node.width)}
        {console.log("height : " + image.node.height)}
        {console.log("format : " + image.node.format)}
        {console.log("bytes : " + image.node.bytes)}
        {console.log("format : " + image.node.format)} */}
        {/* {
          (image.node.context != null) &&
          console.log("alt : " + image.node.context.custom.alt)
        }
        {
          (image.node.context != null) &&
          console.log("caption : " + image.node.context.custom.caption)
        } */}
      </li>

    ))



  )

}

// export default Gallery;