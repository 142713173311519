import React, { useEffect } from "react"
import Gallery from "./gallery"
import ScrollContainer from 'react-indiana-drag-scroll'




const callbackMap = function () {

  console.log("callbackMap");

  //add order number
  const ulgallerywrap = document.querySelectorAll("ul.gallerywrap");
  // console.log("ulgallerywrap : "+ulgallerywrap.length);

  for (let i = 0; i < ulgallerywrap.length; i++) {
    // console.log("i : " + i);
    const lielements = ulgallerywrap[i].querySelectorAll("li");
    for (let j = 0; j < lielements.length; j++) {
      // console.log("j : " + j);
      lielements[j].querySelector("div.clicknum").innerHTML = ((j < 10) ? "0" : "") + (j + 1);
    }
  }
  if (ulgallerywrap.length > 0) {
    // window.removeEventListener('scroll', callbackMap);
  }

}

const CallBackDelay = function () {
  setTimeout(callbackMap, 100)
}

export default function GalerySlider({ typeRef, classRef, pathRef }) {

  // const customRef = React.createRef();


  useEffect(() => {
    // window.addEventListener('scroll', callbackMap);
    // window.addEventListener('DOMContentLoaded', callbackMap);
    CallBackDelay();

  })


  // console.log("typeRef : "+typeRef);


  return (
    <ScrollContainer className="scroll-container sectiongroup" vertical="false" activationDistance="10">
      <ul className={"gallerywrap " + classRef} title="">
        <Gallery typeRef={typeRef} customRef={pathRef}></Gallery>
      </ul>
    </ScrollContainer>

  )

}
